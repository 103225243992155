import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import '@/App.container';

import IOrderService,
    { BusinessLineModel, CreateBusinessLineCommand, BusinessLineTableView, DeleteBusinessLineCommand, User, BusinessLineStatus, EditBusinessLineCommand } from '@/services/iOrderService';
import OrderService from '@/services/OrderService';
import { CreateBusinessLineResponse, DeleteBusinessLineResponse } from '@/services/iOrderService';

import iIdentityService, { UserSimple } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';

import pgaDiContainer from '@/App.container';
import { SharedModule } from '@/feature/Shared/store';
import { Guid } from 'guid-typescript';
import { EditAssociationBusinessLineACOMCommand } from '@/services/iOrderService';

export interface BusinessLinesState {
    BusinessLines: Array<BusinessLineTableView>;
    BusinessLineDetails: DetailsBusinessLineModel;
    BusinessLineCreate: CreateBusinessLineModel;
    BusinessLineDelete: DeleteBusinessLineModel;
    BusinessLineEdit: EditBusinessLineModel;
    ACOMs: Array<UserSimple>
}

export interface DetailsBusinessLineModel {
    BusinessLineDetailsView: BusinessLineModel | null;
}

export interface CreateBusinessLineModel {
    CreateBusinessLineCommand: CreateBusinessLineCommand | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteBusinessLineModel {
    DeleteBusinessLineCommand: DeleteBusinessLineCommand | null;
}

export interface EditBusinessLineModel {
    BusinessLineEditView: BusinessLineModel | null;
    EditAssociationBusinessLineACOMCommand: EditAssociationBusinessLineACOMCommand;
    EditBusinessLineCommand: EditBusinessLineCommand | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'BusinessLineModule', })
class BusinessLinesStore extends VuexModule implements BusinessLinesState {
    private orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);
    private identityService: iIdentityService = pgaDiContainer.get<iIdentityService>(IdentityService);

    public ACOMs = new Array<UserSimple>();
    public BusinessLines: Array<BusinessLineTableView> = new Array<BusinessLineTableView>();
    public BusinessLineDetails: DetailsBusinessLineModel = {
        BusinessLineDetailsView: null,
    };
    public BusinessLineCreate: CreateBusinessLineModel = {
        CreateBusinessLineCommand: null,
        Errors: new Map<string, Array<string>>(),
    };
    public BusinessLineDelete: DeleteBusinessLineModel =  {
        DeleteBusinessLineCommand: null,
    };
    public BusinessLineEdit: EditBusinessLineModel = {
        BusinessLineEditView: null,
        EditAssociationBusinessLineACOMCommand: {
            Id: Guid.parse("00000000-0000-0000-0000-000000000000"),
            ACOM: new Array<Guid>(),
        },
        EditBusinessLineCommand: null,
        Errors: new Map<string, Array<string>>(),
    }


    // GET & SET BUSINESSLINES
    @Action({ rawError: true })
    public async AllBusinessLinesAPI(): Promise<void> {
        const bls: Array<BusinessLineTableView> = await this.orderService.GetBusinessLines();
        this.SET_BUSINESSLINES_TABLE(bls);
    }
    @Mutation
    public SET_BUSINESSLINES_TABLE(bls: Array<BusinessLineTableView>) {
        this.BusinessLines = bls;
    }

    // GET & SET BUSINESSLINE DETAILS
    @Action({ rawError: true })
    public async GetBusinessLineDetails(Id: string): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        const bls: BusinessLineModel = await this.orderService.GetBusinessLine(Id);

        this.SET_BUSINESSLINE_DETAILS(bls);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropBusinessLineDetails() {
        this.SET_BUSINESSLINE_DETAILS(null);
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS(businessLineDetails: BusinessLineModel | null) {
        this.BusinessLineDetails.BusinessLineDetailsView = businessLineDetails;
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS_ID(Id: Guid) {
        if (this.BusinessLineDetails.BusinessLineDetailsView)
            this.BusinessLineDetails.BusinessLineDetailsView.Id = Id;
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS_CODE(Code: string) {
        if (this.BusinessLineDetails.BusinessLineDetailsView)
            this.BusinessLineDetails.BusinessLineDetailsView.Code = Code;
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS_TITLE(Title: string) {
        if (this.BusinessLineDetails.BusinessLineDetailsView)
            this.BusinessLineDetails.BusinessLineDetailsView.Title = Title;
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS_ACOM(acom: Array<any>) {
        if (this.BusinessLineDetails.BusinessLineDetailsView)
            this.BusinessLineDetails.BusinessLineDetailsView.ACOM = acom;
    }
    @Mutation
    public SET_BUSINESSLINE_DETAILS_STATUS(status: BusinessLineStatus) {
        if (this.BusinessLineDetails.BusinessLineDetailsView)
            this.BusinessLineDetails.BusinessLineDetailsView.Status = status;
    }

    // GET, SET & CREATE BUSINESSLINE
    @Action({ rawError: true })
    public async CreateBusinessLine(): Promise<CreateBusinessLineResponse> {
        try {
            SharedModule.SET_ISLOADING(true);
            if (this.BusinessLineCreate.CreateBusinessLineCommand)
                return await this.orderService.CreateBusinessLine(this.BusinessLineCreate.CreateBusinessLineCommand);
            return { Id: Guid.createEmpty()  };
        }
        catch (error) {
            const errs = parseErrors(error);
            this.SET_RESPONSE_CREATE(errs);

            throw error
        }
        finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Action({ rawError: true })
    public async GetACOMs(): Promise<void> {
        const acoms: Array<UserSimple> = await this.identityService.GetACOMs();
        this.SET_ACOMS(acoms);
    }
    @Action({ rawError: true })
    public InitBusinessLineCreate() {
        const cmd: CreateBusinessLineCommand =
        {
            Code: '',
            Title: '',
            ACOM: new Array<string>()

        }

        this.SET_BUSINESSLINE_CREATE(cmd);
    }
    @Action({ rawError: true })
    public DropBusinessLineCreate() {
        this.SET_BUSINESSLINE_CREATE(null);
    }
    @Mutation
    public SET_BUSINESSLINE_CREATE(businessLineCreate: CreateBusinessLineCommand | null) {
        this.BusinessLineCreate.CreateBusinessLineCommand = businessLineCreate;
    }
    @Mutation
    public SET_BUSINESSLINE_CREATE_CODE(Code: string) {
        if (this.BusinessLineCreate.CreateBusinessLineCommand)
            this.BusinessLineCreate.CreateBusinessLineCommand.Code = Code;
    }
    @Mutation
    public SET_BUSINESSLINE_CREATE_TITLE(Title: string) {
        if (this.BusinessLineCreate.CreateBusinessLineCommand)
            this.BusinessLineCreate.CreateBusinessLineCommand.Title = Title;
    }
    @Mutation
    public SET_BUSINESSLINE_CREATE_ACOM(acom: Array<string>) {
        if (this.BusinessLineCreate.CreateBusinessLineCommand)
            this.BusinessLineCreate.CreateBusinessLineCommand.ACOM = acom;
    }
    @Mutation
    public SET_RESPONSE_CREATE(errors: Map<string, Array<string>>) {
        this.BusinessLineCreate.Errors = errors;
    }

    // EDIT BUSINESSLINE
    @Action({ rawError: true })
    public async EditBusinessLine(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.BusinessLineEdit.BusinessLineEditView == null)
                return Promise.reject("Edit Business Line not initialized, try reload the application");

            if(this.BusinessLineEdit.EditBusinessLineCommand)
                await this.orderService.EditBusinessLine(this.BusinessLineEdit.EditBusinessLineCommand);
        } catch (error) {
            const errs = parseErrors(error);
            this.SET_BUSINESSLINE_ERRORS_EDIT(errs);
            throw errs
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Action({ rawError: true })
    public async InitEditBusinessLineCommand(id: string)
    {
        await this.GetBusinessLineToEdit(id);
        const command: EditBusinessLineCommand = 
        {
            Id: this.BusinessLineEdit.BusinessLineEditView?.Id as Guid,
            Code: this.BusinessLineEdit.BusinessLineEditView?.Code ?? "",
            Title: this.BusinessLineEdit.BusinessLineEditView?.Title ?? "",
            ACOM: this.BusinessLineEdit.BusinessLineEditView?.ACOM.map(a => a.Id) as Array<Guid>,
        }
        this.SET_BUSINESSLINE_EDIT_COMMAND(command);    
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_COMMAND(cmd: EditBusinessLineCommand | null) {
        this.BusinessLineEdit.EditBusinessLineCommand = cmd;
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_ID(id: Guid) {
        if(this.BusinessLineEdit.EditBusinessLineCommand)
            this.BusinessLineEdit.EditBusinessLineCommand.Id = id;
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_CODE(code: string) {
        if(this.BusinessLineEdit.EditBusinessLineCommand)
            this.BusinessLineEdit.EditBusinessLineCommand.Code = code;
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_TITLE(title: string) {
        if(this.BusinessLineEdit.EditBusinessLineCommand)
            this.BusinessLineEdit.EditBusinessLineCommand.Title = title;
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_ACOM(acom: Array<Guid>) {
        if(this.BusinessLineEdit.EditBusinessLineCommand)
            this.BusinessLineEdit.EditBusinessLineCommand.ACOM = acom;
    }
    @Mutation
    public SET_BUSINESSLINE_ERRORS_EDIT(errors: Map<string, Array<string>>) {
        this.BusinessLineEdit.Errors = errors;
    }

    // DROP BUSINESSLINE
    @Action({ rawError: true })
    public async DeleteBusinessLine(id: Guid): Promise<DeleteBusinessLineResponse> {
        try {
            SharedModule.SET_ISLOADING(true);
            this.SET_BUSINESSLINE_DELETE({ Id: id });

            if (this.BusinessLineDelete.DeleteBusinessLineCommand)
                return await this.orderService.DeleteBusinessLine(this.BusinessLineDelete.DeleteBusinessLineCommand);
            return { Id: Guid.createEmpty() };
        }
        finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Mutation
    public SET_BUSINESSLINE_DELETE(deleteBusinessLineCommand: DeleteBusinessLineCommand) {
        this.BusinessLineDelete.DeleteBusinessLineCommand = deleteBusinessLineCommand;
    }
    @Mutation
    public SET_ACOMS(acoms: Array<UserSimple>) {
        this.ACOMs = acoms;
    }

    // APPROVE
    @Action({ rawError: true })
    public async ApproveBusinessLine(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.BusinessLineDetails.BusinessLineDetailsView == null)
                return Promise.reject("Details Business Line not initialized, try reload the application");

            const bl = this.BusinessLineDetails.BusinessLineDetailsView;
            const cmd = { Id: bl.Id };
            await this.orderService.ApproveBusinessLine(cmd);

            this.SET_BUSINESSLINE_DETAILS_STATUS(BusinessLineStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // EDIT ASSOCIATION BL - ACOM
    @Action({ rawError: true })
    public async EditAssociationBLAcom(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.BusinessLineEdit.BusinessLineEditView == null)
                return Promise.reject("Edit Business Line not initialized, try reload the application");

            const cmd: EditAssociationBusinessLineACOMCommand = this.BusinessLineEdit.EditAssociationBusinessLineACOMCommand;
            cmd.Id = this.BusinessLineEdit.BusinessLineEditView.Id;
            const response = await this.orderService.EditAssociationBusinessLineACOM(cmd);
        } catch (error) {
            const errs = parseErrors(error);
            this.SET_RESPONSE_EDIT_ASSOCIATION(errs);
            throw errs
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
    @Action({ rawError: true })
    public async GetBusinessLineToEdit(id: string) {
        SharedModule.SET_ISLOADING(true);
        const bl: BusinessLineModel = await this.orderService.GetBusinessLine(id);

        this.SET_BUSINESSLINE_EDIT_ASSOCIATION(bl);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropBusinessLineEdit() {
        this.SET_BUSINESSLINE_EDIT_COMMAND(null);
        this.SET_BUSINESSLINE_EDIT_ASSOCIATION(null);
        this.RESET_BUSINESSLINE_EDIT_ASSOCIATION_COMMAND();
        this.SET_RESPONSE_EDIT_ASSOCIATION(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_ASSOCIATION(businessLineEdit: BusinessLineModel | null) {
        this.BusinessLineEdit.BusinessLineEditView = businessLineEdit;
    }
    @Mutation
    private RESET_BUSINESSLINE_EDIT_ASSOCIATION_COMMAND() {
        this.BusinessLineEdit.EditAssociationBusinessLineACOMCommand = {
            Id: Guid.parse("00000000-0000-0000-0000-000000000000"),
            ACOM: new Array<Guid>(),
        };
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_ASSOCIATION_ID(id: Guid) {
        this.BusinessLineEdit.EditAssociationBusinessLineACOMCommand.Id = id;
    }
    @Mutation
    public SET_BUSINESSLINE_EDIT_ASSOCIATION_ACOM(acom: Array<Guid>) {
        this.BusinessLineEdit.EditAssociationBusinessLineACOMCommand.ACOM = acom;
    }
    @Mutation
    public SET_RESPONSE_EDIT_ASSOCIATION(errors: Map<string, Array<string>>) {
        this.BusinessLineEdit.Errors = errors;
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionBusinessLine(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.BusinessLineDetails.BusinessLineDetailsView == null)
                return Promise.reject("Details Business Line not initialized, try reload the application");

            const bl = this.BusinessLineDetails.BusinessLineDetailsView;
            const cmd = { Id: bl.Id };
            await this.orderService.ApproveDeletionBusinessLine(cmd);

            this.SET_BUSINESSLINE_DETAILS_STATUS(BusinessLineStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const BusinessLineStore = getModule(BusinessLinesStore);
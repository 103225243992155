













import { Component } from "vue-property-decorator"

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessLinesTable from './Components/PgaBusinessLinesTable.vue';


@Component({
	components:
	{
		PgaBusinessLinesTable,
	}
})
export default class BusinessLinesIndex extends mixins(SharedMixin) { }
























import { Component } from "vue-property-decorator";
import { BusinessLineStore } from "../store";
import { BusinessLineTableView } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import PgaBusinessLineStatus from "./PgaBusinessLineStatus.vue";

@Component({
  components: {
    PgaBusinessLineStatus,
  },
})
export default class PgaUsersTableIndex extends mixins(SharedMixin) {
  isLoading = true;

  headers: Array<unknown> = [
    {
      text: "Code",
      align: "start",
      sortable: true,
      value: "Code",
      width: "20%",
      keySearch: "co",
    },
    {
      text: "Title",
      align: "start",
      sortable: true,
      value: "Title",
      keySearch: "ti",
    },
    {
      text: "ACOM",
      align: "start",
      sortable: false,
      value: "ACOM",
      keySearch: "ac",
    },
    {
      text: "Status",
      align: "start",
      value: "Status",
      width: "10%",
    },
  ];

  get businessLines(): Array<BusinessLineTableView> {
    return BusinessLineStore.BusinessLines;
  }

  async mounted(): Promise<void> {
    await BusinessLineStore.AllBusinessLinesAPI();
    this.isLoading = false;
  }

  rowClicked(item: any): void {
    const id = item.Id;
    this.$router.push({ name: "businesslinedetails", params: { id } });
  }
}

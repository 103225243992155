














import { Component } from "vue-property-decorator";
import { BusinessLineStore, CreateBusinessLineModel } from "./store";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessLineCreate from "./Components/PgaBusinessLineCreate.vue";
import { CreateBusinessLineResponse } from "@/services/iOrderService";


@Component({
	components:
	{
		PgaBusinessLineCreate,
	},
})
export default class BusinessLinesPageCreate extends mixins(SharedMixin)
{

	get businessLineCreate() : CreateBusinessLineModel
	{
		return BusinessLineStore.BusinessLineCreate;
	}

	async Create() : Promise<void>
	{
		try
		{
			const response: CreateBusinessLineResponse = await BusinessLineStore.CreateBusinessLine();
			const id: string = response.Id.toString();
			this.$router.replace({ name: 'businesslinedetails', params: { id } });
		}
		// eslint-disable-next-line no-empty
		catch (error) { }
	}

	undo() : void
	{
		this.$router.go(-1);
	}

	mounted() : void
	{
		BusinessLineStore.InitBusinessLineCreate();
		BusinessLineStore.GetACOMs();
	}

	beforeDestroy() : void
	{
		BusinessLineStore.DropBusinessLineCreate();
		BusinessLineStore.BusinessLineCreate.Errors = new Map<string, Array<string>>();
	}
}


















import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";
import { BusinessLineStatus } from "../../../../services/iOrderService";

@Component({})
export default class PgaBusinessLineStatus extends Vue {
  @Prop({ type: String }) Title?: string;
  @Model("", { type: Number }) Model!: BusinessLineStatus;

  get color(): string {
    switch (this.Model) {
      case BusinessLineStatus.Created:
        return "info";
      case BusinessLineStatus.Approved:
        return "success";
      case BusinessLineStatus.DeletionRequest:
        return "warning";
      case BusinessLineStatus.Deleted:
        return "error";
      default:
        return "primary";
    }
  }

  get icon(): string {
    switch (this.Model) {
      case BusinessLineStatus.Created:
        return "mdi-new-box";
      case BusinessLineStatus.Approved:
        return "mdi-check-decagram";
      case BusinessLineStatus.DeletionRequest:
        return "mdi-delete-alert";
      case BusinessLineStatus.Deleted:
        return "mdi-delete-circle";
      default:
        return "mdi-alert-circle";
    }
  }

  get tooltip(): string {
    switch (this.Model) {
      case BusinessLineStatus.Created:
        return "Created";
      case BusinessLineStatus.Approved:
        return "Approved";
      case BusinessLineStatus.DeletionRequest:
        return "Deletion Requested";
      case BusinessLineStatus.Deleted:
        return "Deleted";
      default:
        return "Error";
    }
  }

  get titleDefined(): boolean {
    return this.Title != null;
  }
  get title(): string {
    return this.Title || "";
  }
}

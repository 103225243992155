



































import { Component, Model } from "vue-property-decorator";
import { BusinessLineModel, User } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { BusinessLineStatus } from "@/services/iOrderService";
import PgaBusinessLineStatus from "./PgaBusinessLineStatus.vue";

@Component({
  components: {
    PgaBusinessLineStatus,
  }
})
export default class PgaBusinessLineDetails extends mixins(SharedMixin) {
  @Model("", { type: Object })
  readonly Model!: BusinessLineModel;

  get code(): string {
    return this.Model?.Code ?? "";
  }

  get title(): string {
    return this.Model?.Title ?? "";
  }

  get ACOM(): Array<User> {
    return this.Model?.ACOM ?? new Array<User>();
  }

  get status(): BusinessLineStatus {
    return this.Model?.Status;
  }
}

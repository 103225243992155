















import { Component } from "vue-property-decorator";
import { BusinessLineStore, EditBusinessLineModel } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessLineAcomEdit from './Components/PgaBusinessLineACOMEdit.vue';

@Component({
	components:
	{
		PgaBusinessLineAcomEdit,
	}
})
export default class BusinessLinesACOMPageEdit extends mixins(SharedMixin)
{
	get BusinessLineEdit() : EditBusinessLineModel
	{
		return BusinessLineStore.BusinessLineEdit;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await BusinessLineStore.EditAssociationBLAcom();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
        const id: string = this.$route.params.id;
        await BusinessLineStore.GetACOMs();
		await BusinessLineStore.GetBusinessLineToEdit(id);
	}
	
	beforeDestroy() : void
	{
		BusinessLineStore.DropBusinessLineEdit();
	}
}
















































import { Component, Model } from "vue-property-decorator";
import { BusinessLineStore }  from '../store';
import { BusinessLineModel, User } from '@/services/iOrderService';
import { UserSimple } from '@/services/iIdentityService';

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaBusinessLineEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: BusinessLineModel;

	get code() : string
	{
		return this.Model?.Code ?? "";
	}
	set code(value: string)
	{
		BusinessLineStore.SET_BUSINESSLINE_EDIT_CODE(value);
	}

	get title() : string
	{
		return this.Model?.Title ?? "";
	}
	set title(value: string)
	{
		BusinessLineStore.SET_BUSINESSLINE_EDIT_TITLE(value);
	}

    get acom() : Array<User> {
        return BusinessLineStore.BusinessLineEdit.BusinessLineEditView?.ACOM ?? new Array<User>();
    }
	set acom(acom: Array<User>)
	{
        const acomGuid = acom.map(a => a.Id);
		BusinessLineStore.SET_BUSINESSLINE_EDIT_ACOM(acomGuid);
	}

	get Errors(): Map<string, Array<string>>
	{
		return BusinessLineStore.BusinessLineEdit.Errors;
	}

	get ACOMs(): Array<UserSimple>
	{
		return BusinessLineStore.ACOMs;
	}

}

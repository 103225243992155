















import { Component } from "vue-property-decorator";
import { BusinessLineStore, EditBusinessLineModel } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessLineEdit from './Components/PgaBusinessLineEdit.vue';
import { BusinessLineStatus } from "@/services/iOrderService";
import { SharedModule } from "@/feature/Shared/store";

@Component({
	components:
	{
		PgaBusinessLineEdit,
	}
})
export default class BusinessLinesPageEdit extends mixins(SharedMixin)
{
	get BusinessLineEdit() : EditBusinessLineModel
	{
		return BusinessLineStore.BusinessLineEdit;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await BusinessLineStore.EditBusinessLine();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
        const id: string = this.$route.params.id;
        await BusinessLineStore.GetACOMs();
		await BusinessLineStore.InitEditBusinessLineCommand(id);

		if(this.BusinessLineEdit.BusinessLineEditView &&
			this.BusinessLineEdit.BusinessLineEditView.Status !== BusinessLineStatus.Created)
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE("BusinessLine is already approved, can't be edit anymore");
			SharedModule.SET_ALERT_IS_VISIBLE(true);
			this.$router.go(-1);
		}
	}
	
	beforeDestroy() : void
	{
		BusinessLineStore.DropBusinessLineEdit();
	}
}















































import { Component } from "vue-property-decorator";
import { BusinessLineStore, DetailsBusinessLineModel } from "./store";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaBusinessLineDetails from "./Components/PgaBusinessLineDetails.vue";
import { SharedModule } from "@/feature/Shared/store";
import { BusinessLineStatus } from "@/services/iOrderService";
import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";
import { EmailsStore } from "@/feature/Shared/Email/store";

@Component({
  components: {
    PgaBusinessLineDetails,
  },
})
export default class BusinessLinesPageDetails extends mixins(SharedMixin)
{
	showDialog = false;
	private title = "";
	private message = "";

	get businessLineDetails(): DetailsBusinessLineModel 
	{
		return BusinessLineStore.BusinessLineDetails;
	}

	get businessLineTitle() : string 
	{
		return this.businessLineDetails?.BusinessLineDetailsView?.Title ?? '';
	}

	ShowDialogDelete(): void 
	{
		this.title = "Deleting Business Line";
		this.message = `Are you sure you want to delete the business line '${this.businessLineTitle}'?`;
		this.showDialog = true;
	}

	ShowDialogRequestDelete(): void 
	{
		this.title = "Request Deletion Business Line";
		this.message = `Are you sure you want to requeste deletion of business line '${this.businessLineTitle}'?`;
		this.showDialog = true;
	}

	HideDialog(): void 
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	async mounted(): Promise<void> 
	{
		BusinessLineStore.DropBusinessLineDetails();

		const id: string = this.$route.params.id;
		await BusinessLineStore.GetBusinessLineDetails(id);
	}

	async drop(): Promise<void> 
	{
		if (this.businessLineDetails.BusinessLineDetailsView)
		await BusinessLineStore.DeleteBusinessLine(
			this.businessLineDetails.BusinessLineDetailsView.Id
		);

		this.showDialog = false;
		this.$router.go(-1);
	}

	async approve(): Promise<void> 
	{
		try {
		await BusinessLineStore.ApproveBusinessLine();
		} catch (reason) {
		SharedModule.SET_ALERT_ERROR_MESSAGE(
			`error approving business line: ${reason}`
		);
		SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	async approveDeletion(): Promise<void> 
	{
		try {
		await BusinessLineStore.ApproveDeletionBusinessLine();
		this.$router.go(-1);
		} catch (reason) {
		SharedModule.SET_ALERT_ERROR_MESSAGE(`error approving deletion business line: ${reason}`);
		SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	get isApprovable(): boolean 
	{
		return this.businessLineDetails?.BusinessLineDetailsView?.Status == BusinessLineStatus.Created;
	}

	get isApprovableDeletion(): boolean 
	{
		return this.businessLineDetails?.BusinessLineDetailsView?.Status == BusinessLineStatus.DeletionRequest;
	}

	get isRequestableDeletion(): boolean
	{
		return this.businessLineDetails?.BusinessLineDetailsView?.Status !== BusinessLineStatus.DeletionRequest &&
			this.businessLineDetails?.BusinessLineDetailsView?.Status !== BusinessLineStatus.Created;
	}

	get isEditable() : boolean
	{
		return this.businessLineDetails?.BusinessLineDetailsView?.Status === BusinessLineStatus.Created;
	}

	private edit() : void
	{
		let id: string = this.businessLineDetails.BusinessLineDetailsView?.Id.toString() ?? "";
			this.$router.push({ name: 'businesslineedit', params: { id }});
	}
	private editAssociation() : void
	{
		let id: string = this.businessLineDetails.BusinessLineDetailsView?.Id.toString() ?? "";
			this.$router.push({ name: 'businesslineacomedit', params: { id }});
	}
	
	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["2A4F361C-5633-46B3-9C03-01C6677D0009"],
			Subject: `Request changes for BusinessLine of ${this.businessLineDetails.BusinessLineDetailsView?.Title}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}
}

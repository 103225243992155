













































import { Component, Model } from "vue-property-decorator";
import { BusinessLineStore }  from '../store';
import { CreateBusinessLineCommand } from '@/services/iOrderService';

// import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';
import { UserSimple } from '@/services/iIdentityService';

@Component({})
export default class PgaBusinessLineCreate extends mixins(SharedMixin)
{
	@Model("", { type: Object })
	readonly Model!: CreateBusinessLineCommand;

	get code() : string
	{
		return this.Model?.Code ?? "";
	}
	set code(code: string)
	{
		BusinessLineStore.SET_BUSINESSLINE_CREATE_CODE(code);
	}

	get title() : string
	{
		return this.Model?.Title ?? "";
	}
	set title(title: string)
	{
		BusinessLineStore.SET_BUSINESSLINE_CREATE_TITLE(title);
	}

	get acom(): Array<string>
	{
		return this.Model?.ACOM ?? new Array<string>();
	}
	set acom(acom: Array<string>)
	{
		BusinessLineStore.SET_BUSINESSLINE_CREATE_ACOM(acom);
	}

	get Errors(): Map<string, Array<string>>
	{
		return BusinessLineStore.BusinessLineCreate.Errors;
	}

	get ACOMs(): Array<UserSimple>
	{
		return BusinessLineStore.ACOMs;
	}

}
